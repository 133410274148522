@import "../../../content/constants";

#main-content .summary-widget {
    background-color: @widget-bg;
    color: #fff;

    float: left;
    margin: 10px 20px 0 0;

    h2 {
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .widget-main-content {
        padding: @default-spacer;
        min-height: calc(160px - calc(2 * @default-spacer));
    }

    .counters {
        margin-top: 15px;
        display: flex;
        align-items: center;
    }

    .feature-unavailable-text {
        margin-top: 15px;
    }

    .counter {
        width: 2em;
        line-height: 2em;
        border-radius: 50%;
        text-align: center;
        font-size: 28px;
        border: 4px solid #fff;
        padding: 2px;
    }

    .counter-text {
        margin: 0 15px;
    }
}

@media screen and (max-width: @large-device-width) {
    #main-content {
        .summary-widget {
            float: none;
            margin: 10px 0 0 0;
        }
    }
}

@media screen and (min-width: calc(@large-device-width + 1px)) {
    #main-content {
        .summary-widget {
            min-width: @widget-width + 65px !important;

            .widget-main-content {
                height: calc(160px - calc(2 * @default-spacer));
            }
        }
    }
}

@media screen and (max-width: @small-device-width) {
    #main-content .summary-widget {
        .counter-text {
            margin: 0 5px;
        }
    }
}
