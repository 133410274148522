@import "../../../content/constants";

#main-content .dashboard .widget.upcoming-events {
    width: calc(2 * @widget-width + @default-spacer);

    .header-icon {
        transform: rotate(90deg);
        display: inline-block;
        position: absolute;
        left: 11px;
    }

    .event-filters {
        padding: @default-spacer @default-spacer 0;

        > .filter-button {
            display: inline-block;
            border: 1px solid @blue;
            padding: 0.5rem 1rem;
            background: white;
            color: @blue;
            border-radius: 0;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            text-shadow: none;

            > .filter-button-icon {
                font-size: 12px;
                vertical-align: middle;
                line-height: 12px;
                position: relative;
                left: 7px;
            }

            &:focus {
                box-shadow: none;
            }

            &.active {
                color: @charcoal;

                &.event-type-0 {
                    background-color: @event-type-exam-color;
                    border-color: @event-type-exam-color;
                }
                &.event-type-1 {
                    background-color: @event-type-assessment-color;
                    border-color: @event-type-assessment-color;
                }
                &.event-type-2 {
                    background-color: @event-type-excursion-color;
                    border-color: @event-type-excursion-color;
                }
                &.event-type-3 {
                    background-color: @event-type-school-event-color;
                    border-color: @event-type-school-event-color;
                }
            }
        }
    }

    .agenda {
        margin: 0 @default-spacer @default-spacer;

        .no-data-with-image {
            p {
                text-align: center;
                margin: 0;
            }
        }

        > section {
            padding: 1rem 0;
            border-bottom: solid 1px @light-grey;
            display: flex;
            flex-direction: row;

            &:last-child {
                border-bottom: none;
            }

            > h1 {
                font-weight: normal;
                font-size: 0.8rem;
                display: flex;
                flex-direction: column;
                width: 130px;
                vertical-align: top;
                line-height: 1.4rem;
                margin: 0;

                > small {
                    display: block;
                    font-size: 1.3rem;
                }
            }

            > ul {
                display: flex;
                flex-direction: column;
                flex: 1;
                vertical-align: top;
                margin: 0 !important;
                padding: 0;
                list-style: none;
                max-width: 400px;
                > li {
                    margin: 0 !important;
                    padding-bottom: 0.75rem;

                    position: relative;
                    display: flex;
                    flex-direction: row;

                    > .event-type-indicator {
                        min-width: 1.25em;
                        text-align: center;
                        font-weight: bold;

                        &.event-type-0 {
                            background-color: @event-type-exam-color;
                        }
                        &.event-type-1 {
                            background-color: @event-type-assessment-color;
                        }
                        &.event-type-2 {
                            background-color: @event-type-excursion-color;
                        }
                        &.event-type-3 {
                            background-color: @event-type-school-event-color;
                        }

                        > .vertically-center-this {
                            display: table;
                            height: 100%;
                            width: 100%;

                            > div {
                                display: table-cell;
                                vertical-align: middle;
                            }
                        }
                    }

                    > .event-description {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        > .event-time,
                        > .event-title {
                            margin-left: 15px;
                        }

                        > .event-time {
                            display: block;
                            font-size: 0.8rem;
                        }

                        > .event-title {
                            display: block;
                            font-size: 1rem;
                            font-weight: bold;
                        }
                    }

                    > .event-download {
                        display: flex;
                        align-items: center;
                        margin-left: auto;
                        padding-left: @default-spacer;
                        > a {
                            background-color: @light-grey;
                            padding: 0.6rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1194px) {
    #main-content .dashboard .widget.upcoming-events {
        width: auto;
    }
}

@media screen and (max-width: 767px) {
    #main-content .dashboard .widget.upcoming-events {
        .agenda {
            > section {
                flex-direction: column;
                > ul {
                    margin-top: 0.5rem !important;
                }
            }
        }
    }
}
