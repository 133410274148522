@import "../../../content/constants";

.registerLink {
    background-color: @solid-yellow;
    color: @black;
    font-weight: bold;
    padding: 10px;
    text-decoration: none;
    transition: @transition;
    width: max-content;

    &:hover {
        background-color: rgba(@solid-yellow, 0.85);
    }
}