@import "../../../../content/constants";

.livesEasier {
    background-color: @off-white;
    flex-wrap: wrap;

    > .content > h2 {
        font-weight: 400;
        text-align: center;
    }
}