@import '../../../../content/constants';

#main-content {
  .summary-widget.qlearn {
    background-color: @q-learn-primary;

    .widget-main-content {
      background-color: @q-learn-primary;

      h2::first-letter {
        color: @q-learn-accent;
      }
    }

    .q-learn-summary-link {
      background-color: @q-learn-primary-light;
      display: block;
      line-height: 1.5em;
      text-decoration: none !important;
      color: #fff;
      padding: @half-default-spacer @default-spacer;

      > .chevron {
        float: right;
        margin-top: 4px;
      }
    }

    .link {
      margin-top: 0.75rem;
      a {
        color: @off-white;
      }
    }

    p {
      margin-top: 0;
    }

    .content {
      margin-top: 1rem;
    }
  }
}
