@import '../../../../content/constants';

#main-content .upcoming-events {
    .counter {
        width: 24px !important;
        height: 24px !important;
        line-height: 24px !important;
        font-size: 14px !important;
    }

    .school-event-counter {
        margin-left:1em;
    }

    .view-all-link {
        background-color: @widget-link-bg;
        display: block;
        line-height: 1.5em;
        text-decoration: none !important;
        color: #fff;
        padding: @half-default-spacer @default-spacer;
        > .chevron {
            float: right;
            margin-top: 4px;
        }
    }
}