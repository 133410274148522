@import '../../../content/constants';

#main-content .dashboard .widget.downloads {

    .widget-main-content {
            padding: (@half-default-spacer) @default-spacer;
    }

    .header-icon {
        display: inline-block;
        position: absolute;
        left: 11px;
    }

    .no-downloads-text {
        display: block;
        padding: @half-default-spacer 0 @half-default-spacer 30px;
        position: relative;

        >i {
            position: absolute;
            left: 0;
            top: 10px;
            font-size: 1.5em;
        }
    }

    .download-list {
        list-style: none;
        margin: 0;
        padding-left: 0;

        > li {
            border-bottom: solid 1px @light-grey;

            &:last-child {
                border-bottom: none;
            }

            > a {
                display: block;
                padding: @half-default-spacer 30px @half-default-spacer 0;
                font-weight: bold;
                text-decoration: none;
                position: relative;
                &:hover {
                    text-decoration: underline;
                }
                > .download-icon {
                    position: absolute;
                    right: 10px;
                    top: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 1194px) {
    #main-content .dashboard .widget.downloads {
    }
}

@media screen and (max-width: 767px) {
    #main-content .dashboard .widget.downloads {

    }
}