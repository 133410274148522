#main-content {

  .q-learn-app-message {
    padding-top: 30px;
  }

  .q-learn-app-message-full {
    .wrapper {
      box-sizing: border-box;

      padding-top: 30px;
      padding-left: 40px;
      padding-bottom: 30px;
      background: white;
      max-width: 640px;

      display: flex;
      flex-direction: row;

      .text {
        p {
          margin-top: 0;

          &:last-of-type{
            margin-top: 10px;
          }

          a{
            margin-right: 20px;
          }
        }
      }
    }

    img {
      height: 70px;
      width: 70px;
      float: left;
      margin-right: 30px;
    }
  }

  .q-learn-app-message-small {
    display: none;
  }

  @media screen and (max-width: 767px) {

    .q-learn-app-message-full {
      display: none;
    }

    .q-learn-app-message-small {
      display: block;

      .wrapper {
        padding-top: 30px;
        padding-left: 20px;
        padding-bottom: 30px;
        background: white;
        max-width: 640px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        img {
          height: 45px;
          width: 45px;
          float: left;
          margin-right: 30px;
        }

        .text {
          p {
            margin-top: 0;

            &:last-of-type{
              margin-top: 10px;
            }

            a{
              margin-right: 20px;
            }
          }
        }
      }


    }
  }
}
