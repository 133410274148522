@import '../../../content/constants';

#main-content .dashboard .widget.behaviour {

    @widget-bg: #225692;
    @widget-link-bg: #2962a5;
    @left-margin: 85px;
    .widget-main-content {
        padding: @default-spacer;
        height: calc(120px - calc(2 * @default-spacer));

    }

    .widget-badge {
        color: #fff;
        background-color: @red;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        display: block;
        right: .5em;
        top: .5em;
        text-align: center;
        line-height: 1.5em;
        vertical-align: middle;
    }

    .no-behaviour-events-message{
        color: #fff;
        display: block;
        margin-left: @left-margin;
    }

    background-color: @widget-bg;
    h2 {
        margin-left: @left-margin;
        margin-top: 0;
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .behaviour-icon {
        position: absolute;
        top: @default-spacer;
        left: @default-spacer;
        font-size: 70px;
        color: #fff;
    }

    .behaviour-event {
        > strong {
            display: inline-block;
            padding: .3em .6em;
            margin-right: .5em;
            border: solid 1px #fff;
            border-radius: .3em;
        }
        display: block;
        margin-left: @left-margin;
        color: #fff;
        font-size: .8em;
    }

    .behaviour-event-date {
        color: #fff;
        margin-left: @left-margin;
        display: block;
        margin-top: .5em;
        font-size: .6em;
    }

    h3 {
        margin: 0 15px;
        padding: 15px 5px;
        line-height: 27px;
        color: @charcoal;
        border-bottom: solid 1px @light-blue-grey;
        font-size: 18px;

        strong {
            display: inline-block;
            font-weight: @font-bold;
            color: white;
            padding: 0 @half-default-spacer;
            margin-right: @half-default-spacer;
            border-radius: @border-radius-normal;

            &.Negative,
            &.Incident {
                background: @blue;
            }

            &.Positive {
                background: white;
                color: @blue;
                border: solid 3px @blue;
                line-height: 23px;
                padding: 0 calc(@half-default-spacer - 2);
            }
        }
    }

    .view-all-behaviour-events-link {
        background-color: @widget-link-bg;
        display: block;
        line-height: 1.5em;
        text-decoration: none !important;
        color: #fff;
        padding: @half-default-spacer @default-spacer;
        > .chevron {
            float: right;
            margin-top: 4px;
        }

    }

}

@media screen and (max-width: 1194px) {
    #main-content .dashboard .widget.behaviour {

    }
}

@media screen and (max-width: 767px) {
    #main-content .dashboard .widget.behaviour {

    }
}