@import '../../../content/constants';

#main-content .dashboard .widget.payments {

    @widget-bg: #103a6a;
    @widget-link-bg: #1c4a7e;
    @left-margin: 85px;

    .widget-main-content {
        padding: @default-spacer;
        height: calc(120px - calc(2 * @default-spacer));

    }

    .widget-badge {
        color: #fff;
        background-color: @red;
        border-radius: 50%;
        width: 1.3rem;
        height: 1.3rem;
        position: absolute;
        display: block;
        right: .3rem;
        top: .3rem;
        text-align: center;
        line-height: 1.3rem;
        vertical-align: middle;
        font-size: 0.9rem;
    }

    background-color: @widget-bg;
    h2 {
        margin-left: @left-margin;
        margin-top: 0;
        color: #fff;
        font-size: 1.1rem;
        font-weight: bold;
    }

    .payment-icon {
        position: absolute;
        top: @default-spacer;
        left: @default-spacer;
        font-size: 70px;
        color: #fff;
    }

    .payments-due-message {
        color: #fff;
        display: block;
        margin-left: @left-margin;
        font-weight: normal;
        font-size: 2em;
    }
    .no-payments-due-message {
        color: #fff;
        display: block;
        margin-left: @left-margin;
    }

    .view-all-payments-link {
        background-color: @widget-link-bg;
        display: block;
        line-height: 1.5em;
        text-decoration: none !important;
        color: #fff;
        padding: @half-default-spacer @default-spacer;
        > .chevron {
            float: right;
            margin-top: 4px;
        }

    }

}

@media screen and (max-width: 1194px) {
    #main-content .dashboard .widget.payments {

    }
}

@media screen and (max-width: 767px) {
    #main-content .dashboard .widget.payments {

    }
}