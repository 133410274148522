@import "../../content/constants";

.consent-container {
  max-width: @maximum-content-width-dashboard !important;

  .consent-request-filters {
    input {
      margin-left: 0 !important;
      border-color: #c6c6c6 !important;
    }

    #consentRequestsStudentFilter {
      width: 85%;
    }
  }
}

.request-container {
  max-width: 680px;
}

.request-summary {
  margin-top: 20px;
}

.due-date {
  color: #f04444;
}

div.date {
  font-size: 0.8rem;
  text-align: end;
}

.consent-link {
  color: #d20259;
}

.consent-requests {
  border-top: 0.25rem solid #f4dd1b;
  margin-top: 0.9rem;
}

.consent-request {
  background-color: #ffffff;
  display: flex;
  flex: 1 1 auto;
  border-bottom: solid 0.2rem @light-grey;
  padding: 0 1rem 0.5rem;
  margin-bottom: .25rem;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: #eff6ff
    }
  }
}

.container-left {
  display: flex;
  flex: 2 1 auto;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-width: 1%;
}

.consent-right-align {
  margin-left: auto;
}

.container-right {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.status-chip {
  display: flex;
  align-items: flex-end;
  flex: 1 1 auto;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  border: none;
  cursor: default;
  height: 2rem;
  outline: none;
  font-size: 0.825rem;
  white-space: nowrap;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  margin-top: 0.5rem;
  border-radius: 0.1rem;
  padding: 0 0.5rem;
  color: #000;

  &.pending {
    background-color: #fef3c6;
  }

  &.granted {
    background-color: #aee9d1;
  }

  &.denied,
  &.withdrawn {
    background-color: #fed3d1;
  }

  &.mixed {
    background-color: #dbeaff;
  }

  &.expired {
    background-color: #e4e5e7;
  }
}

.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-bottom: 0.1rem;
  margin-top: 0.5rem;
}

.supporting-text {
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}

a.btn {
  margin-right: 0 !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.2rem !important;
}

.user-icon {
  margin-right: 0.3rem;
}

#main-content {
  .no-records {
    margin-top: 0.5rem;
  }

  .consent-request-title {
    margin-bottom: 1rem;
  }
}

#main-content {
  .consent-form {
    h2 {
      max-width: none;
      box-sizing: border-box;
    }

    .usa-accordion__heading {
      max-width: none;
    }
  }
}

.consent-form {
  @import (less) 'uswds/dist/css/uswds.css';
  @import (less) 'formiojs/dist/formio.form.css';
  @import (less) '@formio/uswds/dist/uswds.css';

  background: white;
  padding: 0.5rem;

  border-bottom: solid 0.2rem #e8e8e8;

  ul {
    padding-inline-start: 1rem;
  }

  li,
  dd,
  p {
    a {
      color: @blue;
    }
  }

  div {
    .btn-link {
      color: @blue;
    }

    .btn-primary {
      background-color: @blue;
    }
  }

  textarea {
    box-sizing: border-box;
  }

  .grid-col-9 {
    width: auto;
  }

  [class*="grid-col"] {
    max-width: none;
  }

  // some forms have configure, preview, print wrapped in a panel
  .consent-form-panel > .card {
    > .card-header {
      display: none;
    }

    > .card-body {
      padding: 0;
    }
  }

  .consent-info-panel > .card > .card-body {
    padding: 1.25rem 0;
  }

  .consent-form-panel,
  .consent-info-panel {
    > .card {
      &.border {
        border: 0 !important;
      }
    }
  }

  .consent-fine-print p,
  .consent-fine-print ul,
  .consent-fine-print ol {
    margin-bottom: 0;
  }

  .consent-super-fine-print ol {
    padding-left: 1.75rem;
  }

  .page-break {
    break-before: page;
  }

  .usa-prose h3 {
    font-size: 1.75rem;
    margin: 0.5rem 0;
    font-family: inherit;
  }

  .usa-prose {
    > table th,
    table th {
      border: 0;
    }
  }

  p.usa-alert,
  div.usa-alert {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  .consent-form-panel > .usa-accordion--bordered {
    border: none !important;
    padding: 0;
    margin: 0;

    > .usa-accordion__heading .usa-accordion__button {
      display: none;
    }

    > .usa-accordion__content {
      border: 0;
      padding: 0;
    }
  }

  .usa-button {
    display: inline-block;
    border: 1px solid @blue;
    padding: .5rem 1rem;
    background: white;
    color: @blue;
    border-radius: 0;
    margin-right: .5rem;
    margin-bottom: .5rem;
    text-shadow: none;
    width: auto;

    &:hover {
      background: @blue;
      color: white;
      border: 1px solid @blue;
    }
  }

  .col-sm-12 {
    margin-left: 0;
  }

  // submit button
  .formio-component-button.formio-form-group {
    text-align: right;
  }

  @media screen and (max-width: @medium-device-width) {
    dl.row.grid-row {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      dd, dt {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  &.read-only {
    button[type='submit'] {
      display: none;
    }
  }
}
