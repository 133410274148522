@import '../../../../content/constants';

.notify-link {
    background-color: @widget-link-bg;
    display: block;
    line-height: 1.5em;
    text-decoration: none !important;
    color: #fff;
    padding: @half-default-spacer @default-spacer;
    > .chevron {
        float: right;
        margin-top: 4px;
    }
}

@media screen and (max-width: 320px) {
    #main-content .absences{
        .counter {
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            font-size: 14px !important;
        }
    }
}