@import "../../content/constants";

.enrolment-status {
    display: inline-block;
    background: @light-blue;
    border-radius: 3px;
    padding: 0.2em 0.5em 0.3em;
    font-weight: 600;
    margin: 0.25em 0.1em;
}

.enrolment-status-active {
    background: @light-green;
}
