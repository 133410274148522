@import '../../../content/constants';

#main-content .dashboard .widget.reports {
    .widget-badge {
        color: #fff;
        background-color: @red;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        display: block;
        right: .5em;
        top: -2.5em;
        text-align: center;
        line-height: 1.5em;
        vertical-align: middle;
    }
    .widget-main-content {
        padding: (@half-default-spacer) @default-spacer;
    }
    .report-period-link {
        display: block;
        padding: @half-default-spacer 30px @half-default-spacer 0;
        position: relative;
        font-weight: bold;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        > .navigate-icon {
            position: absolute;
            right: 10px;
            top: 15px;
        }
    }

    .no-reports-text {
        display: block;
        padding: @half-default-spacer 0 @half-default-spacer 30px;
        position: relative;
        > i {
            position: absolute;
            left: 0;
            top: 15px;
            font-size: 1.5em;
        }
    }

    .view-all-reports-link {
        padding: @half-default-spacer 0;
        color: @blue !important;
        text-decoration: underline !important;
        text-align: left;
        display: block;
    }
}

@media screen and (max-width: 1194px) {
    #main-content .dashboard .widget.reports {
        //width: auto;
    }
}

@media screen and (max-width: 767px) {
    #main-content .dashboard .widget.reports {

    }
}