@import "../../../../content/constants";

&.functionality {
    flex-direction: column;
    row-gap: 40px;

    .functionalityList {
        display: flex;
        flex-wrap: wrap;
        gap: 30px 40px;
        justify-content: center;
        list-style: none;
        max-width: 900px;
        padding: 0;

        > li {
            align-items: center;
            column-gap: 15px;
            display: grid;
            grid-template-columns: min-content 1fr;
            max-width: 400px;
            width: 50%;

            @media screen and (max-width: @medium-device-width) {
                width: 80%;
            }

            h4 {
                margin: 0;
            }

            &:nth-last-child(1):nth-child(odd) {
                grid-column: span 2;
                justify-self: center;
            }
        }
    }

    .functionalityDisclaimer {
        color: rgba(#000000, 0.6);
        font-size: 14px;
        max-width: 400px;
        text-align: center;
    }
}