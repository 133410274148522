.mobile-app-message-full {
    fieldset {
        padding-top: 30px;
    }

    img {
        height: 70px;
        width: 70px;
        float: left;
        margin-right: 30px;
    }
}

.mobile-app-message-small {
    display: none;
}

@media screen and (max-width: 767px) {

    .mobile-app-message-full {
        display: none;
    }

    .mobile-app-message-small {
        display: block;

        fieldset {
            padding-top: 30px;
        }

        img {
            height: 45px;
            width: 45px;
            float:left;
            margin-right: 30px;
        }
        p{
            margin-top: 5px;
        }
    }
}
