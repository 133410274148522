@import '../../../../content/constants';

#main-content .payments {
    
    .counter-text {
        margin: 0 15px;
        p {
            margin-top:2px !important;
        }
        .outstanding-amount{
            font-size: 25px;
        }
    }

    .view-all-link {
        background-color: @widget-link-bg;
        display: block;
        line-height: 1.5em;
        text-decoration: none !important;
        color: #fff;
        padding: @half-default-spacer @default-spacer;
        > .chevron {
            float: right;
            margin-top: 4px;
        }
    }
}