@import '../../../content/constants';

#main-content .dashboard .widget {
    /* Standard display for non-top widgets */
    .widget-feature-unavailable {

        .widget-main-content {
            padding: @default-spacer;
            > p {
                color: #525252;
                font-size: .8em;
                text-align: left;
                line-height: 1.4em;
                margin: 0;
            }
        }
        .fake-link {
            display: none;
        }

    }

    /* Block display for top widgets */
    &.attendance,
    &.behaviour,
    &.payments {
        .widget-feature-unavailable {
            @widget-bg: #999;
            @widget-link-bg: #525252;

            .widget-main-content {
                h2 {
                    margin-left: 0;
                }
                background-color: @widget-bg;
                height: 80px;
                padding: @default-spacer;
                > p {
                    color: #fff;
                    font-size: .8em;
                    text-align: left;
                    line-height: 1.4em;
                    margin: 0;
                }
            }
            .fake-link {
                background-color: @widget-link-bg;
                display: block;
                line-height: 1.5em;
                text-decoration: none !important;
                color: #fff;
                padding: @half-default-spacer @default-spacer;
                > .chevron {
                    float: right;
                    margin-top: 4px;
                }
            }

        }
    }
}