@import "../../content/constants";

.push-notification-setting {
    .field-row {
        margin: @default-spacer @half-default-spacer 0 0;
        display: inline-flex;
        align-items: center;
        width: 100%;

        .field-label {
            width: 90%;
            span {
                display: inline-block;
                width: 90%;
            }
            .help-text {
                font-size: 0.8em;
                color: @darker-grey;
            }
        }
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        min-width: 60px;
        height: 34px;
        border-radius: 34px;

        /* Hide default HTML checkbox */
        input {
            display: none;
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: @darker-grey;
            transition: 0.4s;
            border-radius: 34px;

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: @off-white-light;
                transition: 0.4s;

                border-radius: 50%;
            }
        }

        input:disabled + .slider {
            background-color: @grey;
        }

        input:checked:disabled + .slider {
            background-color: @light-sky-blue;
        }

        input:checked + .slider {
            background-color: @blue;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px @blue;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }
}
