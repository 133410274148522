@import "../../../../content/constants";

.childrenGrow {
    background-color: @off-white;
    flex-direction: column;
    padding: 60px 30px;
    row-gap: 40px;

    > .content {
        align-items: center;
        max-width: 750px;
        text-align: center;

        > .registerLink {
            margin-top: 40px;
        }
    }
}