@import "../../../../content/constants";

.processGuide {
    background-color: @blue;
        color: @white;
        column-gap: 20px;
        flex-direction: column;

        .processGuideList {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: center;
            list-style: none;
            padding: 0;

            > li {
                display: grid;
                flex: 1 1 175px;
                grid-template-rows: min-content auto auto;
                justify-items: center;
                max-width: 300px;
                row-gap: 15px;
                text-align: center;

                h4 {
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }
}