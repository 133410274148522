.onePlace {
    flex-wrap: wrap;
    
    > .content {
        > h2 {
            font-size: 32px;
            margin: 0;
        }

        > span {
            font-size: 20px;
        }
    }
}