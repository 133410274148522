.card-row {
  display: flex;
  flex-direction: column;
  .card-logos {
    margin-left: 100px;
  }
}

@media screen and (max-width: 767px) {
  .card-row {
    .card-logos {
      margin-left: 0;
    }
  }
}
