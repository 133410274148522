@import '../../../content/constants';

#main-content .dashboard .widget.timetable {

    .widget-main-content {
        padding: @half-default-spacer @default-spacer;
    }

    .no-timetable-message{
        display: block;
        margin-top: @half-default-spacer;
    }

    .timetable-description {
        margin: @half-default-spacer 0 @default-spacer;
        font-weight: bold;
        font-size: 1.2em;
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;

        > li {
            border-bottom: solid 1px @light-grey;

            &:last-child {
                border-bottom: none;
            }
            margin: 0;
            padding: 5px 0;
            list-style: none;

            p {
                margin: 0;
                text-align: left;
                float: left;

                &.room {
                    float: right;
                }
            }

            h4 {
                font-size: 16px;
                margin: 0;
                line-height: 30px;
                font-weight: @font-bold;
                clear: both;
            }
        }

    }
    .view-timetable-link {
        padding: @half-default-spacer 0;
        color: @blue !important;
        text-decoration: underline !important;
        text-align: left;
        display: block;

    }
}

@media screen and (max-width: 1194px) {
    #main-content .dashboard .widget.timetable {
        //width: auto;
    }
}

@media screen and (max-width: 767px) {
    #main-content .dashboard .widget.timetable {

    }
}