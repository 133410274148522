.login-form {
    .forgot-password-link {
        margin: 5px 0 5px 200px;
        display: block;
    }

    .mfa-options {
        display: flex;
        flex-direction: column;
        gap: 15px;

        > * {
            margin-left: 30px;
        }
    }
}

@media screen and (max-width: 767px) {
    .login-form {
        .forgot-password-link {
            margin: 5px 0;
        }
    }
}