@import '../../../content/constants';

#main-content {
    .combined-payments {
      max-width: @maximum-content-width-dashboard !important;

        p {
            max-width: @maximum-content-width-dashboard !important;
        }
        .upcoming-payments, .payment-history {
            ul {
                list-style-type: none !important;
                padding-left: 0px !important;
            }
        }
        .feature-unavailable-details {
            border-top: 4px solid #f4dd1b;
            background: #fff;
            margin-top: 30px;
            padding: @default-spacer @default-spacer;
        }

        .outstanding-payment-filters {
            .form-row {
                label {
                    width: 120px !important;
                }
                input[type="text"] {
                    margin-left: 0;
                    border-color: #c6c6c6;
                }
            }
        }

        .payment-history-filters {
            .form-row {
                label {
                    width: 120px !important;
                }
                input[type="text"] {
                    margin-left: 0;
                    border-color: #c6c6c6;
                }
            }
        }
    }
}

.combined-payments{
  fieldset {
      max-width: 750px !important;
  }
  .add-another-absence {
      max-width: @maximum-text-width;
      margin-right: 0px;
      margin-left: 0px;
  }
  .form-row {
      input[type="text"],
      input[type="date"],
      textarea,
      select,
      .multiselect {
          width: 450px !important;
          margin-left: 0px !important;
      }

      [id^=outstandingPaymentStudentFilter] > span > button,
      [id^=outstandingPaymentSchoolFilter] > span > button,
      [id^=paymentHistoryStudentFilter] > span > button,
      [id^=paymentHistorySchoolFilter] > span > button
      {
            width: 450px !important;
      }

      .multiselect{
        margin-right: 10px;
        float:left;
      }
  }
  .remaining {
      padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .combined-payments {
      .form-row {
          input[type="text"],
          input[type="date"],
          textarea,
          select {
              width: 300px !important;
              margin-left: 0px !important;
          }

          input[type="checkbox"] {
              margin-left: 0px !important;
          }

          [id^=outstandingPaymentStudentFilter] > span > button,
          [id^=outstandingPaymentSchoolFilter] > span > button,
          [id^=paymentHistoryStudentFilter] > span > button,
          [id^=paymentHistorySchoolFilter] > span > button
          {
            width: 300px !important;
          }
      }
      .remaining {
          padding-left: 0px;
      }
  }
}