@import "../../../content/constants";

.landingPageSection {
    align-items: center;
    background-color: @white;
    column-gap: 75px;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    justify-items: center;
    padding: 30px;

    .content {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        row-gap: 20px;

        > span:not(:last-of-type) {
            font-size: 20px;
        }

        > span:last-of-type {
            font-size: 14px;
        }
    }

    > img {
        height: auto;
        max-height: 350px;
        max-width: 100%;
    }

    > img:first-child,
    > .content:first-child {
        justify-self: flex-start;
    }

    > img:last-child,
    > .content:last-child {
        justify-self: flex-end;
    }
}