@import '../../content/constants';

#main-content {
    .dashboard {

        > .row > div {
            float: left;
            margin: 0 @default-spacer 0 0;

            &.push-right-2-col {
                position: relative;
                left: 660px;
                min-width: 310px;
                min-height: 1px;
            }
            &.pull-left-1-col {
                position: relative;
                left: -330px;
            }
            &.only-1-col {
                float: none;
            }
        }

        .widget {
            position: relative;
            width: @widget-width;
            margin-bottom: @default-spacer;
            vertical-align: top;
            padding: 0;
            &.standard-widget {
                border-top: solid 4px @yellow;

                background: white;
                margin-top: 55px;
                h2 {
                    font-size: @font-size-normal * 1.6;
                    line-height: 33px;
                    color: @charcoal;
                    margin: 0;
                    padding: 0 15px 0 15px;
                    position: absolute;
                    top: -45px;
                    left: 0;
                }
            }

            p {
                text-align: right;
                margin: 0 15px;

                .btn {
                    padding-left: 15px !important;
                    padding-right: calc(@default-spacer * 2) !important;
                    margin-right: 0 !important;
                }
            }

        }
    }
}

@media screen and (max-width: 1194px) {

    #main-content {

        .dashboard {

            > .row > div {
                float: none;
                margin-right: 0;

                &.pull-left-1-col,
                &.push-right-2-col {
                    position: static;
                    left: auto;
                }
            }

            .widget {
                width: auto;

                h2 {
                    max-width: none;

                    &.no-data {
                        span {
                            width: 200px;
                        }
                    }
                }

                h3, h4, h5, h6, p, ul, ol {
                    max-width: none;
                }

            }
        }
    }
}