.unsupported-browser {
    white-space: unset;
    height: auto;
    margin: none;
    padding: 1rem;
}
#main-content {
    form {
        .submit {
            a.btn {
                margin: 20px 10px 0 0 !important;
            }
        }
    }
}