.register-form {
    .mfa-options {
        display: flex;
        flex-direction: column;
        gap: 15px;

        > * {
            margin-left: 30px;
        }
    }
}