@import "../../content/constants";

#main-content {

    .reports-drop-down {
        width: 300px;
    }

    div.reports-container{
        max-width: @maximum-content-width-dashboard;
        position:relative;
    }

    ul.reports {
        margin: @default-spacer 0 0 0;
        padding: 0;
        max-width: @maximum-content-width-dashboard;

        li {
            position: relative;
            margin: 0 0 3px 0;
            padding: 0;
            list-style: none;
            background: white;

            .expand {
                position: absolute;
                top: 8px;
                right: @default-spacer;
                display: block;
                line-height: 34px;
                height: 30px;
                width: 30px;
                overflow: hidden;
                text-align: center;
                font-family: @icon-font;
                font-size: @font-size-extra-large;
                background: @off-white;
                text-decoration: none;
                color: @dark-red;
                border-radius: @border-radius-normal;
            }

            &.alternate {
                h3 {
                    background: #f3fafb;
                }

                .expand {
                    background: white;
                }
            }

            div {
                position: relative;
                background: @off-white-light;
                padding: 0 @default-spacer @default-spacer @default-spacer;

                h5 {
                    margin: 0;
                    padding: 5px @half-default-spacer;
                    font-weight: @font-black;
                    font-size: @font-size-normal;
                    line-height: @line-height-normal;
                    background: @yellow;
                }

                h6 {
                    margin: 0;
                    position: absolute;
                    top: @default-spacer;
                    right: @default-spacer;
                    font-size: @font-size-large;
                    line-height: @line-height-extra-large;
                    padding: 5px @half-default-spacer;
                    font-weight: @font-regular;

                    strong {
                        font-weight: @font-bold;
                        margin-right: calc(@default-spacer / 4);
                    }
                }

                p {
                    max-width: none;
                    margin-top: 0;
                    padding-top: @default-spacer;
                }

                &.subject-grade {
                    background: none;
                    padding: 8px 0 8px 0;
                    border-left: solid calc(@default-spacer / 4) @yellow;

                    h3 {
                        margin: 0;
                        font-size: 22px;
                        line-height: @line-height-extra-large;
                        /*padding: 16px 160px 16px @default-spacer*.75;*/
                        padding: 0 calc(@default-spacer * .75) 0 calc(@default-spacer * .75);
                        /*border-left: solid @default-spacer/4 @yellow;*/
                        font-weight: @font-bold;
                        background: white;
                        float: left;
                    }

                    h4 {
                        margin: 0 calc(@default-spacer * 3) 0 0;
                        color: @dark-grey;
                        font-weight: @font-black;
                        font-size: @font-size-normal;
                        line-height: 30px;
                        width: 100%;
                        max-width: none;

                        span {
                            margin-right: 376px;
                            margin-bottom: -40px;
                            display: block;
                            float: right;
                        }
                        strong {
                            position: relative;
                            top: 4px;
                            float: right;
                            margin-right: 10px;
                            background: @blue;
                            color: white;
                            font-weight: @font-black;
                            text-align: center;
                            min-width: 36px;
                            border-radius: @border-radius-normal;
                            margin-left: calc(@default-spacer / 4);
                            box-sizing: border-box;
                            padding: 0 8px;
                            &.text-grade {
                                font-size: 28px;
                                top:0;
                            }
                            &.text-grade-long {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #main-content {
        ul.reports {
            li {
                div {
                    &.subject-grade {
                        h4 {
                            float: left;
                            clear: both;
                            margin-left: calc(@default-spacer * .75);
                            padding-left: 15px;
                            width: 100%;
                            span {
                                margin-right: 0px;
                                display: inline;
                                float: none;
                            }
                            strong {
                                float: right;
                                margin-right: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}