@import "../../../content/constants";

#main-content {
    .combined-upcoming-events {
        max-width: @maximum-content-width-dashboard !important;
        .header-icon {
            transform: rotate(90deg);
            display: inline-block;
            position: absolute;
            left: 11px;
        }
        p {
            max-width: @maximum-content-width-dashboard !important;
        }

        .upcoming-event-filters {
            input {
                margin-left: 0px !important;
                border-color: #c6c6c6 !important;
            }
            #eventTypeFilter,
            #studentFilter,
            #schoolFilter {
                width: 85%;
            }
        }

        .feature-unavailable-details {
            border-top: 4px solid #f4dd1b;
            background: #fff;
            margin-top: 30px;
            padding: @default-spacer @default-spacer;
        }

        .agenda {
            border-top: 4px solid #f4dd1b;
            background: #fff;
            margin-top: 30px;

            .no-events-message {
                text-align: left;
                margin: 0 @default-spacer 0 @default-spacer;
                padding: @default-spacer 0;
            }

            > section {
                padding: 1rem 0;
                border-bottom: solid 1px @light-grey;
                margin: 0 20px 20px;
                display: flex;
                flex-direction: row;

                @media screen and (min-width: @medium-device-width) {
                    /* prevent wrapping upcoming events on devices wider than 768px */
                    white-space: nowrap;
                    overflow: hidden;
                }

                &:last-child {
                    border-bottom: none;
                }

                > h1 {
                    font-weight: normal;
                    font-size: 0.8rem;
                    display: flex;
                    flex-direction: column;
                    width: 130px;
                    vertical-align: top;
                    line-height: 1.4rem;
                    margin: 0;

                    > small {
                        display: block;
                        font-size: 1.3rem;
                    }
                }

                > ul {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    vertical-align: top;
                    margin: 0 !important;
                    padding: 0;
                    list-style: none;
                    > li {
                        margin: 0 !important;
                        padding-bottom: 0.75rem;

                        position: relative;
                        display: flex;
                        flex-direction: row;

                        > .event-type-indicator {
                            min-width: 1.25em;
                            text-align: center;
                            font-weight: bold;

                            &.event-type-0 {
                                background-color: @event-type-exam-color;
                            }
                            &.event-type-1 {
                                background-color: @event-type-assessment-color;
                            }
                            &.event-type-2 {
                                background-color: @event-type-excursion-color;
                            }
                            &.event-type-3 {
                                background-color: @event-type-school-event-color;
                            }

                            > .vertically-center-this {
                                display: table;
                                height: 100%;
                                width: 100%;

                                > div {
                                    display: table-cell;
                                    vertical-align: middle;
                                }
                            }
                        }

                        > .event-description {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            > .event-time,
                            > .event-title,
                            > .event-student-name,
                            > .event-school-name {
                                margin-left: 15px;
                                margin-right: 15px;
                            }

                            > .event-time,
                            > .event-school-name,
                            > .event-student-name {
                                display: block;
                                font-size: 0.8rem;
                            }

                            > .event-title {
                                font-size: 1rem;
                                font-weight: bold;
                                white-space: normal;
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                            }
                        }

                        > .event-download {
                            display: flex;
                            align-items: center;
                            margin-left: auto;
                            padding-left: @default-spacer;
                            > a {
                                background-color: @light-grey;
                                padding: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.combined-upcoming-events {
    fieldset {
        max-width: 750px !important;
    }

    .form-row {
        input[type="text"],
        input[type="date"],
        textarea,
        select,
        .multiselect {
            width: 450px !important;
            margin-left: 0px !important;
        }

        [id="eventTypeFilter"] > span > button,
        [id="studentFilter"] > span > button,
        [id="schoolFilter"] > span > button {
            width: 450px !important;
        }

        .multiselect {
            margin-right: 10px;
            float: left;
        }
    }
    .remaining {
        padding-left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .combined-upcoming-events {
        .form-row {
            input[type="text"],
            input[type="date"],
            textarea,
            select {
                width: 300px !important;
                margin-left: 0px !important;
            }

            input[type="checkbox"] {
                margin-left: 0px !important;
            }

            [id="eventTypeFilter"] > span > button,
            [id="studentFilter"] > span > button,
            [id="schoolFilter"] > span > button {
                width: 300px !important;
            }
        }
        .remaining {
            padding-left: 0px;
        }
    }
}

@media screen and (max-width: 1194px) {
    #main-content .combined-upcoming-events {
        width: auto;
    }
}

@media screen and (max-width: 767px) {
    #main-content .combined-upcoming-events {
        .agenda {
            > section {
                flex-direction: column;
                > ul {
                    margin-top: 0.5rem !important;
                }
            }
        }
    }
}
