@import "../../../content/constants";

#main-content {
    .feature-unavailable-details {
        p {
            max-width: none !important;
        }
        border-top: 4px solid #f4dd1b;
        background: #fff;
        margin-top: 30px;
        padding: @default-spacer @default-spacer;
    }
}

.multiple-absences {
    fieldset {
        max-width: 750px !important;
    }

    .form-row {
        input[type="text"],
        input[type="date"],
        textarea,
        select,
        .multiselect {
            width: 450px !important;
            margin-left: 0px !important;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        [id^="studentAndSchool"] > span > button {
            width: 450px !important;
        }

        .multiselect {
            margin-right: 10px;
            float: left;
        }

        &.submit {
            max-width: 750px !important;
        }
    }
    .remaining {
        padding-left: 0px;
    }
}

@media screen and (max-width: 767px) {
    .multiple-absences {
        .form-row {
            input[type="text"],
            input[type="date"],
            textarea,
            select,
            .multiselect {
                width: 300px !important;
            }

            input[type="checkbox"] {
                margin-left: 0px !important;
            }

            [id^="studentAndSchool"] > span > button {
                width: 300px !important;
            }
        }
        .remaining {
            padding-left: 0px;
        }
    }
}
