@import "../../../../content/constants";

.userReviews {
    padding: 0 30px 60px;
    position: relative;

    > .reviewCarousel {
        display: flex;
        margin-top: -37.5px;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;

        > .review {
            align-items: center;
            display: flex;
            flex: 1 0 100%;
            flex-direction: column;
            max-width: 100%;
            scroll-snap-align: start;
            text-align: center;

            > .content > .userImage > img {
                border: 1px solid @grey;
                border-radius: 50%;
                height: 75px;
                width: 75px;
            }
        }

        > .navButtons {
            bottom: 20px;
            column-gap: 15px;
            display: flex;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);

            > .navButton {
                background-color: rgba(@blue, 0.5);
                border-radius: 50%;
                height: 15px;
                width: 15px;

                &.selected {
                    background-color: @blue;
                }

                &:hover {
                    background-color: @blue;
                    cursor: pointer;
                }
            }
        }
    }
}