@import "../../../content/constants";
@import "../../../content/site";

.verify-container {
  margin-bottom: 4rem;

  #main-content {
    #greenid-container {
      // Element added by greenId
      padding: 0 2rem 2rem;

      .btn {
        .base-btn-styles;
        margin-top: 2rem;
      }

      h1 {
        font-size: 1rem;
        background: @yellow;

        margin-left: -2rem;
        margin-right: -2rem;

        padding: 0.5rem 0 0.5rem 2rem;
      }

      h2 {
        font-size: 24px;
        font-weight: @font-regular;
      }

      #greenid-source-header {
        #greenid-source-title {
          display: block;
        }

        #greenid-source-list-container {
          .dropdown-toggle {
            margin-top: 0.5rem;
          }
        }
      }

      .btn-default, .btn-primary {
        border-color: @off-white;
      }


      .dropdown-toggle .caret {
        display: none;
      }

      .form-control,
      input[type="text"] {
        border-radius: 0;
        font-size: @font-size-small;
        height: 44px;
        font-family: Arial, "Liberation Sans", FreeSans, sans-serif;
      }

      .input-group-addon {
        border-radius: 0;
      }

      .control-label {
        font-size: @font-size-small @main-font;
        font-family: @main-font, @backup-font;
      }

      .glyphicon-question-sign,
      .greenid-helpicon {
        color: white;
        border: 2px solid black;
        border-radius: 50%;
        background: black;
      }
    }
  }

  @media screen and (max-width: 767px) {
    #main-content form#dvsForm.long-labels .form-row {
      label {
        float: left;
        width: 190px;
      }

      .tooltip {
        margin-top: 5px;
        margin-left: 0;
        height: auto;

        label {
          margin-bottom: 0;
        }

        > div {
          height: auto;
          clear: both;
          width: auto;
          margin: 0;
          border: solid 1px #d0d0d0;
          position: absolute;
          left: 5%;
          right: 5%;

          div {
            background: white;

            h5 {
              background: none;
              padding-left: @mobile-padding;
              padding-right: @mobile-padding;
            }

            p {
              margin-left: @mobile-padding;
              margin-right: @mobile-padding;
            }

            label {
              margin-top: -1px;
              margin-right: -1px;
            }
          }
        }
      }
    }
  }

  #main-content {
    #not-enough-id-dialog {
      h4 {
        font-size: 1rem;
        font-weight: bold;

        background: @yellow;
        margin-top: 0;
        margin-left: -1rem;
        padding: 0.5rem 1rem 0.5rem 1.0rem;
        width: calc(100% + 2rem);
      }

      li {
        margin-top: 1rem;
      }

      span {
        display: block;
      }

      .margin-top-1 {
        margin-top: 1rem;
      }

      .note {
        font-size: smaller;
      }
    }
  }
}
