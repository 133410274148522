@import "../../../../content/constants";

.appDownload {
    background-color: @blue;
    color: @white;
    flex-wrap: wrap;
    row-gap: 30px;

    > img {
        margin-bottom: -38px;
        filter: drop-shadow(0px 4px 6px #000000FF)
            drop-shadow(0px -10px 6px #0004)
            drop-shadow(-10px -10px 6px #0004)
            drop-shadow(10px -10px 6px #0004);
    }

    .content {
        > span {
            font-size: 20px;
        }

        > .appLinks {
            align-items: center;
            display: flex;
            margin-left: -10px;
            text-align: center;

            > span {
                font-size: 20px;
            }

            > a:not(.login) {
                border-radius: 13px;
                display: inline-block;
                height: 76px;

                > img {
                    box-sizing:border-box;
                    height: 76px;
                    width: 175px;
                }

                &.apple {
                    > img {
                        padding: 12px;
                    }
                }
            }

            > .login {
                font-size: 20px;
                margin-left: 12px;
                width: 160px;
        
                a {
                    align-items: center;
                    background-color: @mid-blue;
                    border: 1px solid @white;
                    color: @white;
                    display: flex;
                    justify-content: center;
                    padding: 10px 16px;
                    text-decoration: none;
                    transition: @transition;
                    width: 100%;
                    width: -moz-available;
                    width: -webkit-fill-available;
                    width: fill-available;
        
                    &:hover {
                        background-color: rgba(@blue, 0.85);
                    }
                }
            }

            @media screen and (max-width: @medium-device-width) {
                align-items: flex-start;
                flex-direction: column;

                > span {
                    margin-left: 15px;
                    width: 160px;
                }

                > .login {
                    margin-top: 10px;
                    width: 145px;
                }
            }
        }
    }
}